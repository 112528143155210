import React from "react"
import { Link, useStaticQuery, graphql } from "gatsby"

import Layout from "../../components/layout/layout"
import SEO from "../../components/seo"
import BigButton from "../../components/buy-equipment/big-buttons"
import WrappingList from "../../components/buy-equipment/wrapping-list"

import styles from "../buy-equipment.module.css"

const LabEquipment = () => {

  const data = useStaticQuery(graphql`
    query {
      allCategory(
        sort: {
          fields: [name]
          order: ASC
        }, 
        filter: {
          parentCategorySlug: {
            eq: "/lab-equipment"
          }
        }
      ) {
        nodes {
          slug
          name
        }
      }
    }
  `)
  return (
    <Layout>
      <SEO
        title={`Used Pharmaceutical Equipment & Biotech Instruments For Sale`}
        description={`Surplus Solutions carries a wide variety of used pharmaceutical lab, packaging, and processing equipment. Contact us today for more information on how we can help solve your equipment needs.`}
        />
      <div className="row columns">
        <ul className="nav-buy">
          <li className="selected">
            <Link to="/buy-equipment/lab-equipment">Lab Equipment</Link>
          </li>
          <li>
            <Link to="/buy-equipment/packaging-equipment">
              Packaging Equipment
            </Link>
          </li>
          <li>
            <Link to="/buy-equipment/processing-equipment">
              Processing Equipment
            </Link>
          </li>
        </ul>
        <h4 className="headings mt2 clearfix">Lab Equipment</h4>
        <p>
          Surplus Solutions offers an extensive inventory of used lab equipment
          for the chemistry, medical, and biotechnology industries.
        </p>
        <p>
          Our inventory includes a wide selection of used analyzers,
          chromatographs, centrifuges, chilling and refrigeration equipment,
          scales and balances, lab automation systems, bioreactors and
          fermenters, glassware, and much more.
        </p>
        <p>
          Whether you&apos;re outfitting a new lab or simply replacing or adding
          a single piece of equipment, Surplus Solutions can provide you with
          the equipment you&apos;re looking for. Browse our full inventory of
          used surplus lab equipment below, or contact us to discuss customized
          solutions to your lab equipment needs.
        </p>
      </div>

      <WrappingList
        containerClassName={styles.container}
        data={data.allCategory.nodes}
        slugPrefix={"/catalog"}
        />

      <BigButton />
    </Layout>
  )
}

export default LabEquipment
